import React, { useState, useEffect, useMemo } from 'react';
import Layout from '../layouts/index';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import translationsEn from '../locales/en.json';
import translationsEs from '../locales/es.json';
import TuneIcon from '@mui/icons-material/Tune';
import SortIcon from '@mui/icons-material/Sort';
import FilterModal from '../components/filter-modal';

const Category = ({ category, locale }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [maxPrice, setMaxPrice] = useState(0);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState(0);
  const [step, setStep] = useState(1);
  const allColors = useMemo(() => category.products.map(product => product.color).filter((value, index, self) => self.indexOf(value) === index), [category.products]);
  const [colorFilters, setColorFilters] = useState(allColors);
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);

  useEffect(() => {
    const maxProductPrice = Math.max(...category.products.map(product => parseFloat(product.price)));
    setMaxPrice(maxProductPrice);
    setSelectedMaxPrice(maxProductPrice);
    setStep(maxProductPrice / 100);
  }, [category.products]);

  const toggleFilters = () => {
    setFilterModalIsOpen(!filterModalIsOpen);
  };

  const handleColorFilterChange = (event) => {
    const { value, checked } = event.target;
    const updatedFilters = checked ? [...colorFilters, value] : colorFilters.filter(color => color !== value);
    setColorFilters(updatedFilters.length === allColors.length ? allColors : updatedFilters);
  };

  const handleAllColorsChange = (event) => {
    if (event.target.checked) {
      setColorFilters(allColors);
    } else {
      setColorFilters([]);
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const filteredProducts = useMemo(() => {
    if (colorFilters.length === 0) {
      return [];
    }
    return category.products.filter(product => {
      const price = parseFloat(product.price);
      return price <= selectedMaxPrice && colorFilters.includes(product.color);
    });
  }, [category.products, selectedMaxPrice, colorFilters]);

  const sortedProducts = useMemo(() => {
    const filtered = filteredProducts;
    return filtered.sort((a, b) => {
      if (sortOrder === 'asc') {
        return parseFloat(a.price) - parseFloat(b.price);
      } else {
        return parseFloat(b.price) - parseFloat(a.price);
      }
    });
  }, [filteredProducts, sortOrder]);

  const translations = locale === 'es' ? translationsEs : translationsEn;

  return (
    <div className="category-display__item" key={category.id}>
      <div className="Category">
        <div className="Category__details">
          <h1 className="Category__name">{category.name}</h1>
          <div className="Category__filters">
            <button onClick={toggleSortOrder} className="sort-button" aria-label="Sort By Price">
              <SortIcon style={{ transform: sortOrder === 'asc' ? 'scaleY(-1)' : 'scaleY(1)' }} />
            </button>
            <button onClick={toggleFilters} className="filter-toggle-button" aria-label="Filter Products">
              <TuneIcon />
            </button>
            <FilterModal
              isOpen={filterModalIsOpen}
              onRequestClose={toggleFilters}
              className="fiters-layout"
            >
              <div className="filters">
                <h2 className="filter__name">{translations['filters']}</h2>
                <div className="filter__price">
                  <h3 className="price-name">{translations['price']}</h3>
                  <label htmlFor="price-range">{translations['max-price']} {selectedMaxPrice.toFixed(2)}€</label>
                  <input
                    type="range"
                    id="price-range"
                    min="0"
                    max={maxPrice}
                    value={selectedMaxPrice}
                    step={step}
                    onChange={(e) => setSelectedMaxPrice(parseFloat(e.target.value))}
                  />
                </div>
                <div className="filter__color">
                  <h3 className="color-name">{translations['colors']}</h3>
                  <label>
                    <input
                      type="checkbox"
                      value="all"
                      checked={colorFilters.length === allColors.length}
                      onChange={handleAllColorsChange}
                    />
                    {translations['all']}
                  </label>
                  {allColors.map(color => (
                    <label key={color}>
                      <input
                        type="checkbox"
                        value={color}
                        checked={colorFilters.includes(color)}
                        onChange={handleColorFilterChange}
                      />
                      {color}
                    </label>
                  ))}
                </div>
              </div>
            </FilterModal>
          </div>
        </div>
        <div className="Catalogue">
          {sortedProducts.map((product) => (
            <div className="Catalogue__item" key={product.id}>
              <Link to={`/${locale}/product/${product.seourl}`}>
                <div className="Product__image">
                  <Img fluid={product.image.fluid} alt={product.name} />
                </div>
                <div className="Product__details">
                  <div className="Product__name">{product.name}</div>
                  <div className="Product__price">{product.price}€</div>
                </div>
              </Link>
              <div
                className="Product snipcart-add-item"
                data-item-id={product.id}
                data-item-price={product.price}
                data-item-image={product.image.url}
                data-item-name={product.name}
                data-item-url={`/${locale}/product/${product.seourl}`}
                data-item-custom1-name="SKU"
                data-item-custom1-value={product.sku}
              >
                <span className="Product__buy">{translations['buy']}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CategoryPage = ({ data, pageContext }) => {
  const { locale } = pageContext;
  return (
    <Layout 
      site={data.site} 
      seo={{ ...data.site.globalSeo, ...data.site.faviconMetaTags }}
      lang={locale}
      title={data.category.name + " - OS DO CASAL"}
      description={data.category.name}>
      <div className="category-page">
        <Category category={data.category} locale={locale} />
      </div>
    </Layout>
  );
};

export const query = graphql`
query($categoryId: String, $locale: String) {
  category: datoCmsCategory(id: { eq: $categoryId }, locale: { eq: $locale }) {
    id
    name
    products {
      id
      name
      price
      color
      seourl
      image {
        url
        fluid(maxWidth: 300, imgixParams: { fm: "webp" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
  site: datoCmsSite {
    globalSeo {
      siteName
      titleSuffix
      fallbackSeo {
        title
        description
        image {
          url
        }
      }
    }
    faviconMetaTags {
      tags
    }
  }
}
`;

export default CategoryPage;